import { ArrowUp, Save } from "@carbon/icons-react";
import { Accordion, AccordionItem, Button, Content, Form, InlineNotification, Loading } from "@carbon/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { loadDataSources, loadPipelines } from "../../../Utils/HTTPUtils.js";
import {
    arrayToCommaString,
    changeInput,
    formComponents,
    isFormEmpty,
    pipelineDefault,
    sanitizeItemInputs,
    sourcesToLocal,
    validateForm
} from "../../../Utils/utils.js";
import CronPicker from "../../CronPicker/index.js";
import WIPComponent from "../../WIPComponent/index.js";
import Join from "./Join/index.js";
import Kdd from "./Kdd/index.js";
import Sanitize from "./Sanitize/index.js";
import { sourcesActionsRequestThunk, pushPipeline } from "../../../store/reducers/DAToolReducer";
import { NotificationModel } from "../../../Models/Notification.js";
import { pushNotification } from "../../../store/reducers/notificationsReducer.js";

const Index = ({ }) => {
    const [keyState, setKeyState] = useState(v4())
    const dispatch = useDispatch();
    const location = useLocation();
    const datoolStore = useSelector((state) => state.datool)
    const [itemState, setItemState] = useState('')
    const [localState, setLocalState] = useState({ ...pipelineDefault })
    const [errorState, setErrorState] = useState({ ...pipelineDefault })
    const [sourcesState, setSourcesState] = useState([])
    const [submitState, setSubmitState] = useState(false)
    const [resetState, setResetState] = useState(false)
    const [targetsState, setTargetsState] = useState("")
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])
    const [inputsState, setInputsState] = useState([])
    const inputRef = useRef()
    const [loading, setLoading] = useState(false)
    const [headerState, setHeaderState] = useState(recalculateHeader())

    const navigate = useNavigate()

    useEffect(() => {
        setLoading(() => true)
        loadPipelines(setLoading, dispatch)
        loadDataSources(setLoading, dispatch)
    }, [])

    useEffect(() => {
        // Enter here in case of edit
        if (location.state && location.state.item.data) {
            if (typeof location.state.item.data !== "string") {
                setItemState(() => ({ ...location.state.item.data }))
            } else {
                setItemState(() => '')
            }
        }
    }, [location])

    useEffect(() => {
        setHeaderState(() => recalculateHeader())
        getSources()
        if (itemState !== '' && itemState !== {}) {
            setLocalState((state) => ({ ...state, ...itemState }))
        }
    }, [itemState])

    useEffect(() => {
        getSources()
    }, [datoolStore.sources])

    useEffect(() => {
        setTargetsState(() => (sourcesState.filter((s) => getSelectedSources().includes(s.id))))
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState, sourcesState])

    useEffect(() => {
        setInputsState(() => formComponents(inputItemsState, setLocalState, setErrorState, localState, errorState, inputRef, keyState))
    }, [inputItemsState])

    const actions = {
        updateJoin: (data) => {
            setLocalState((state) => ({ ...state, join_steps: [...data] }))
        },
        updateSanitize: (data) => {
            setLocalState((state) => ({ ...state, sanitization_steps: [...data] }))
        },
        updateKdd: (data) => {
            setLocalState((state) => ({ ...state, kdd_steps: [...data] }))
        },
    }
    return (
        <Content className="page-container data-process">
            <section className={'section-display'}>
                {loading && <div className="status-container">
                    <Loading
                        description="Active loading indicator" withOverlay={false}
                    />
                </div>}
            </section>
            <div>
                {headerState}
                <div className="inner-content">
                    <Form>
                        <div className={'form-content-pipeline'}>
                            {/* TODO:Delete InlineNotification and validate data origin */}
                            {inputsState}

                            {/* <InlineNotification kind={'info'} hideCloseButton={true}>
                                <div className={'info-notification'}>Seleccione los orígenes que desea poder utilizar en
                                    las fases <ArrowUp size={16} /></div>
                            </InlineNotification> */}
                            <CronPicker item={itemState} saveCounter={submitState}
                                output={changeInput} setParentState={setLocalState}
                                setParentErrorState={setErrorState}></CronPicker>
                        </div>
                    </Form>
                </div>
            </div>
            <section className={'data-process-section'}>
                <Accordion align="end" size={'lg'}>
                    <AccordionItem title={<h3>Fase Sanitización</h3>} onHeadingClick={() => setResetState(true)}>
                        <Sanitize
                            item={itemState && itemState.sanitization_steps && !isFormEmpty(itemState.sanitization_steps) ? itemState.sanitization_steps : ''}
                            output={(returnData) => actionChooser(returnData)} inModal={false}
                            reset={resetState} setReset={setResetState} parentTargets={targetsState}></Sanitize>
                    </AccordionItem>
                    <AccordionItem title={<h3>Fase Join</h3>} onHeadingClick={() => setResetState(true)}>
                        <div className={'data-process-phase'}>
                            <Join item={itemState && itemState.join_steps && !isFormEmpty(itemState.join_steps) ? itemState.join_steps : ''}
                                output={(returnData) => actionChooser(returnData)}
                                inModal={false}
                                reset={resetState} setReset={setResetState} parentTargets={targetsState}></Join>
                        </div>
                    </AccordionItem>
                    <AccordionItem title={<h3>Fase KDD</h3>} onHeadingClick={() => setResetState(true)}>
                        <div>
                            <Kdd item={itemState && itemState.kdd_steps && !isFormEmpty(itemState.kdd_steps) ? itemState.kdd_steps : ''}
                                output={(returnData) => actionChooser(returnData)}
                                inModal={false}
                                reset={resetState} setReset={setResetState} parentTargets={targetsState}></Kdd>
                        </div>
                    </AccordionItem>
                </Accordion>
                <div className={'fixed-bottom-save'}>
                    <Button type={'button'} className={'save-pipeline'} kind={'primary'} renderIcon={Save}
                        iconDescription={'Guardar'}
                        onClick={(e) => saveNewItem(e)}>Guardar</Button>
                </div>
            </section>

        </Content>
    )

    function saveNewItem(e) {
        setSubmitState(() => true)
        e.preventDefault()
        const noValidate = ['id', 'global_type', 'join_steps', 'sanitization_steps', 'kdd_steps'];

        if (validateForm(localState, setErrorState, noValidate)) {

            let dispatcherPipeline = { type: 'postPipeline', id: '', data: localState }
            if (itemState !== '') {
                dispatcherPipeline.type = 'putPipeline'
                dispatcherPipeline.id = itemState.id ?? ''
            }

            // dispatch(pushPipeline(localState))

            dispatch(sourcesActionsRequestThunk(dispatcherPipeline)).unwrap()
                .then(() => {
                    navigate("/data-process")
                })
                .catch((error) => {
                    dispatch(pushNotification(new NotificationModel('Error en la carga',
                        `HTTP error: ${error.http_status_code}, contacte con el administrador. soporte@espossible.com`,
                        'error').toJson()))
                }).finally(() => {
                    setLocalState(() => ({ ...sanitizeItemInputs(pipelineDefault) }))
                    setSubmitState(() => false)
                    setResetState(() => ({ reset: true, saved: true }))
                    setLoading(() => false)
                })
        } else {
            validateForm(localState, setErrorState, noValidate)
        }
        //It only works if this line is commented
        //setSubmitState(() => false)
    }

    function actionChooser(returnData) {
        actions[returnData.action](returnData.data)
    }

    /** 
     * Funtion that sets data sources states
    */
    function getSources() {
        let sources = sourcesToLocal(datoolStore.sources)
        if (sources && Object.keys(sources).length > 0) {
            sources = sources.map((e) => ({ id: e.id, text: e.name }))
            setSourcesState([...sources])
        }
    }

    function getSelectedSources() {
        return typeof localState.sources !== 'string' ? arrayToCommaString(localState.sources.map((i) => i.id)) : localState.sources
    }

    function recalculateHeader() {
        return <h2>{itemState === '' ? 'Nueva' : 'Editar '} Pipeline</h2>
    }

    function recalculateInputs() {
        return [
            {
                type: 'text',
                id: 'name',
                invalidText: errorState.name,
                labelText: 'Nombre de la Pipeline',
                placeholder: 'Introduce un nombre para la Pipeline',
                value: localState.name,
                invalid: errorState.name,
            },
            {
                type: 'multiselect',
                id: 'sources',
                invalidText: errorState.sources,
                labelText: 'Origen de datos',
                placeholder: 'Seleccione el origen de datos',
                value: getSelectedSources(),
                invalid: errorState.sources,
                types: sourcesState
            }
        ]
    };

}
export default Index
