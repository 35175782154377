import { getAxiosPrivate } from "./common";

const fileManagerRequestUrl = "/file/httpdownloader/"

const url = {
    getSources: (store) => getAxiosPrivate(store).get('/sources/'),
    postDownloader: (store, opt) => getAxiosPrivate(store).post('/sources/downloaders/', opt.data),
    putDownloader: (store, opt) => getAxiosPrivate(store).put(`/sources/downloaders/${opt.id}/`, opt.data),
    deleteDownloader: (store, opt) => getAxiosPrivate(store).delete(`/sources/downloaders/${opt.id}/`),
    postUploader: (store, opt) => getAxiosPrivate(store, { "Content-Type": "multipart/form-data" }).post('/sources/file_uploads/', opt.data),
    putUploader: (store, opt) => getAxiosPrivate(store).put(`/sources/file_uploads/${opt.id}/`, opt.data),
    deleteUploader: (store, opt) => getAxiosPrivate(store).delete(`/sources/file_uploads/${opt.id}/`),
    postDBSource: (store, opt) => getAxiosPrivate(store).post('/sources/db_sources/', opt.data),
    putDBSource: (store, opt) => getAxiosPrivate(store).put(`/sources/db_sources/${opt.id}/`, opt.data),
    deleteDBSource: (store, opt) => getAxiosPrivate(store).delete(`/sources/db_sources/${opt.id}/`),
    // Pipelines
    getPipelines: (store) => getAxiosPrivate(store).get('/pipelines/'),
    postPipeline: (store, opt) => getAxiosPrivate(store).post('/pipelines/', opt.data),
    putPipeline: (store, opt) => getAxiosPrivate(store).put(`/pipelines/${opt.id}/`, opt.data),
    deletePipeline: (store, opt) => getAxiosPrivate(store).delete(`/pipelines/${opt.id}/`),
}

export const fileManagerRequest = async (store) => {
    return getAxiosPrivate(store)
        .get(`${fileManagerRequestUrl}`)
}
export const actionSourcesRequest = async (store, type, id, data) => {
    return url[type](store, { id, data })
}
