import { ArrowDown, FaceDizzy } from "@carbon/icons-react";
import { Accordion, AccordionItem, Content, Loading } from "@carbon/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataSourceTable from "../../components/DataIngressSection/DataSourceTable/index.js";
import DBImport from "../../components/DataIngressSection/DBImport/index.js";
import FileManager from "../../components/DataIngressSection/FileManager";
import FileUploader from "../../components/DataIngressSection/FileUploader/index.js";
import { loadDataSources } from "../../Utils/HTTPUtils.js";
import { sourcesToLocal } from "../../Utils/utils.js";

const Index = () => {
    const dispatch = useDispatch();
    const datoolStore = useSelector((state) => state.datool)
    const [loading, setLoading] = useState(false)
    const [sourceItems, setSourceItems] = useState([])

    useEffect(() => {
        setLoading(() => true)
        loadDataSources(setLoading, dispatch)
    }, [])
    useEffect(() => {
        FileManagerItems()
    }, [datoolStore.sources, loading])

    const actionSelector = {
        updateItem: (returnData) => {
            updateItem(returnData)
        },
        deleteItem: (returnData) => {
            deleteItem(returnData)
        },

    }
    const FileManagerItems = () => {
        let items = null
        let sources = sourcesToLocal(datoolStore.sources)
        if (sources && Object.keys(sources).length > 0) {
            items = (<DataSourceTable data={sources}
                notForTable={['url', 'columns', 'connection', 'table', 'columns', 'global_type']}
                title={'Fuentes de datos'}
                output={(returnData, type) => actionSelector[type](returnData)}
                className={'pointer'}></DataSourceTable>);
        }

        if (!items && !loading) {
            items = (<div className={'no-data-source'}>
                <h3>No tiene orígenes definidos <FaceDizzy size={24} /></h3>
                <p>Puede crear nuevos orígenes desde la siguiente sección </p>
                <ArrowDown size={24} />
            </div>)
        }
        setSourceItems(() => items)
    }


    return (<Content id="data-analysis-page" className="page-container data-ingress">
        <section className={'section-display'}>
            {loading && <div className="status-container">
                <Loading
                    description="Active loading indicator" withOverlay={false}
                />
            </div>}
            {sourceItems}
        </section>
        <section className={'section-import'}>
            <h2>Seleccione el tipo de fuente:</h2>
            <Accordion align="end" size={'lg'}>
                <AccordionItem title={<h3>URL(HTTP)</h3>}><FileManager
                    key={'newItem'} item={''}
                    output={(returnData) => newItem(returnData)}></FileManager></AccordionItem>
                <AccordionItem title={<h3>Archivo</h3>}>
                    <FileUploader key={'newItem'} item={''} types={['.csv']} inModal={false}
                        output={(returnData) => newItem(returnData)}></FileUploader>
                </AccordionItem>
                <AccordionItem
                    title={<h3>Base de datos</h3>}><DBImport key={'newItem'} item={''}
                        output={(returnData) => newItem(returnData)}></DBImport></AccordionItem>
            </Accordion>
        </section>
    </Content>)

    function updateItem(data) {
        loadDataSources(setLoading, dispatch)
    }

    function deleteItem(data) {
        loadDataSources(setLoading, dispatch)
    }

    function newItem(data) {
        loadDataSources(setLoading, dispatch)
    }


}

export default Index
